import style from './Newsletter.module.css'
import formStyle from '../styles/form.module.css'

import classNames from 'classnames/bind'
import useTranslation from 'next-translate/useTranslation'
import { LayoutContext } from './sg/Layout'
import Loading from './Loading'
import useAuth from '../hooks/useAuth'
import { useRef, useContext, useState } from 'react'
import { useRouter } from 'next/router'

export default function Newsletter ({ context }) {
  const { t } = useTranslation('main')
  const { locale } = useRouter()
  const c = classNames.bind(style)
  const cf = classNames.bind(formStyle)
  const [loading, setLoading] = useState()
  // eslint-disable-next-line no-unused-vars
  const [layoutContext, setLayoutContext] = useContext(context || LayoutContext)
  const { isAuthed } = useAuth()

  const formElem = useRef()
  const firstNameElem = useRef()
  const emailElem = useRef()

  const handleSubmit = e => {
    e.preventDefault()

    const subscribe = async () => {
      setLoading(true)
      const response = await window.fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          firstName: firstNameElem.current.value.trim(),
          email: emailElem.current.value.trim()
        })
      })
      if (response.ok) {
        setLayoutContext({ success: t('newsletter-subscribe-success') })
        formElem.current.setAttribute('novalidate', '')
        formElem.current.reset()
      } else {
        setLayoutContext({ message: t('newsletter-subscribe-error') })
      }
      setLoading(false)
    }

    formElem.current.removeAttribute('novalidate')

    if (formElem.current.checkValidity()) {
      subscribe()
    }
  }

  return !isAuthed && locale.startsWith('hidenewsletterfornow') ? (
    <form ref={formElem} noValidate onSubmit={handleSubmit}>
      <div className={c('newsletter')}>
        <div>
          <h2>{t('newsletter-title')}</h2>
        </div>
        <div className={c('nobreak') + ' ' + cf('inputfield')}>
          <input ref={firstNameElem} minLength={2} required autoComplete='given-name' placeholder={t('newsletter-firstname-placeholder')} />
        </div>
        <div className={c('nobreak') + ' ' + cf('inputfield')}>
          <input ref={emailElem} autoComplete='email' required type='email' placeholder={t('newsletter-email-placeholder')} />
        </div>
        <div>
          <button type='submit'>{loading ? <Loading /> : t('newsletter-subscribe-button')}</button>
        </div>
      </div>
    </form>
  ) : null
}
